import './App.css';

import React from "react"
import { useState, useEffect } from "react";

import games from './data/all_games_with_tags_no_dups.json'
import filters from './data/filters_data.json'
import M from 'materialize-css';


const genres = filters['genres']

const tags = filters['common_tags']
const how_to_play = filters['how_to_play']

let autocomplete_dict = {}
tags.forEach(t => autocomplete_dict[t] = null)

const month_and_years = [...new Set( games.map(g => g.bundle)) ].map( b => b.split('-') )
const months =  [
  "january", "february", "march", "april", "may", "june",
  "july", "august", "september", "october", "november", "december"
]
const years = [
  ...new Set( month_and_years.map(x => x[1]) )
].sort(function(a, b) { return b - a; })

let games_with_dates = games.map(g => {
  const [month, year] = g.bundle.split('-');
  g.date = new Date(year, months.indexOf(month), 1);
  return g
})

// Sort by date then name
games_with_dates.sort((a, b) => b.date - a.date || (a.name > b.name ? 1 : -1) )


function App() {

  const [q, setQ] = useState("");
  const [searchParam] = useState(["genres", "name"]);
  const [tagFilters, setTagFilters] = useState([]);
  const [genreFilters, setFilterParam] = useState([]);
  const [numPlayersFilters, setNumPlayersFilters] = useState([]);
  const [end_month, setEndMonth] = useState(games_with_dates[0].bundle.split('-')[0])
  const [end_year, setEndYear] = useState(games_with_dates[0].bundle.split('-')[1])
  const [start_month, setStartMonth] = useState(games_with_dates[games_with_dates.length - 1].bundle.split('-')[0])
  const [start_year, setStartYear] = useState(games_with_dates[games_with_dates.length - 1].bundle.split('-')[1])

  useEffect(() => {
    var elems = document.querySelectorAll('.modal');
    M.Modal.init(elems, {});
   }, [
    searchParam, genreFilters, q, tagFilters, numPlayersFilters,
    start_month, start_year, end_month, end_year
  ])

  function addTag(tag) {
    setTagFilters(t => [...t, tag])
  }
  function deleteTag(tag) {
    setTagFilters(tags => tags.filter(t => t !== tag)) 
  }

  useEffect(() => {
    var collapsibles = document.querySelectorAll('.collapsible');
    M.Collapsible.init(collapsibles, {accordion: false});

    var chips = document.querySelectorAll('.chips.common-tags');
    M.Chips.init(chips, {
    placeholder: "Filter by Steam tag",
    onChipAdd: (e, chip) => addTag( chip.firstChild.nodeValue ),
    onChipDelete: (e, chip) => deleteTag( chip.firstChild.nodeValue ),
    autocompleteOptions: {
      data: autocomplete_dict,
      limit: Infinity,
      minLength: 0,
    }
  });
  }, []);

  function prop_contains_string(data, prop, str) {
    return data.filter(item => (
          item[prop]
              .toString()
              .toLowerCase()
              .indexOf(str) > -1
    ))
  }

  function search(items) {
    const start_date = new Date(start_year, months.indexOf(start_month), 1);
    const end_date = new Date(end_year, months.indexOf(end_month), 1);
    const between_dates = items.filter(x => x.date >= start_date && x.date <= end_date)

      const with_genre = between_dates.filter(i => 
        genreFilters.every(genre => i.genres.includes(genre))
      )

      const with_how_to = with_genre.filter(i => 
        numPlayersFilters.every(how_to_play => i.tags.includes(how_to_play))
      )

      const with_genre_and_tags = with_how_to.filter(i =>
        tagFilters.every(tag => i.tags.includes(tag))
      )

      const full_filter = prop_contains_string(with_genre_and_tags, 'name', q.toLowerCase())

      return full_filter
  }

  function card_layout(game) {
    return (
        <li className="game card small sticky-action" key={game.name}>
          <div className="card-image">
            <a className="modal-trigger" href={"#modal-" + game.name}>
              <img src={game.image} alt={game.name} loading={"lazy"}></img>
            </a>
          </div>
          <div className="card-content">
              <span className="card-title">{game.name}</span>
          </div>
          <div id={"modal-" + game.name} className="modal">
            <div className="modal-content">
              <h4>{game.name}</h4>
              {
                [...new Set(game.genres)].map(g => (
                  <div className="chip" key={game.name + g}>{g}</div>
                  ))
              }
              <div>
                {
                  [...new Set(game.tags)].map(g => (
                    <div className="chip" key={game.name + g}>{g}</div>
                    ))
                }
              </div>
            </div>
            <div className="modal-footer">
            </div>
          </div>
          <div className="card-action">
            <span className="left"><a href={game.bundle_url} target="_blank" rel="noreferrer">{game.bundle}</a></span>
            <span className="right"><a href={"https://store.steampowered.com/app/" + game.appid} target="_blank" rel="noreferrer">steam page</a></span>
          </div>
        </li>
    )
  }

  return (
          <div className="container-fluid">

          <div className="chip tuto-chip">
              Explore the humble bundle monthly and choice games collection using the following filters
              <i className="close material-icons" style={{"marginLeft": "auto"}}>close</i>
          </div>

            <ul className="collapsible filters-collapsible">

              <li className='active'>
                <div className="collapsible-header">
                  <i className="material-icons">filter_none</i>Basic Filters
                  </div>
                <div className="collapsible-body">
                  <div className="row">

                    <div className="input-field col s12 m5 l3">
                      <input
                          name="search-form"
                          id="search-form"
                          placeholder="Search by game name"
                          value={q}
                          onChange={(e) => setQ(e.target.value)}
                      />
                    </div>

                    <div className="input-field col s12 m3 l2">
                      <select multiple
                        onChange={(e) => {
                          const options = Array.from(e.target.selectedOptions);
                          setFilterParam(options.map(o => o.value));
                        }}>
                            {
                              genres.map( (x) => <option key={x}>{x}</option> )
                            }
                          </select>
                          <label className='basic-filter-input-label'>Filter by Genre</label>
                    </div>

                    <div className="input-field col s12 m3 l2">
                      <select multiple
                        onChange={(e) => {
                          const options = Array.from(e.target.selectedOptions);
                          setNumPlayersFilters(options.map(o => o.value));
                        }}>
                            {
                              how_to_play.map( (x) => <option key={x}>{x}</option> )
                            }
                          </select>
                          <label className='basic-filter-input-label'>How to play</label>
                    </div>

                  </div>
                </div>
              </li>

              <li>
                <div className="collapsible-header">
                  <i className="material-icons">filter_frames</i>Advanced filters
                  </div>
                <div className="collapsible-body">
                  <div className="chips common-tags chips-autocomplete input-field"></div>
                </div>
              </li>

              <li>
                <div className="collapsible-header">
                  <i className="material-icons">date_range</i>Filter by date
                  </div>
                <div className="collapsible-body date-filter">
                  <div><span>Display games from</span></div>

                  <div className="input-field year-select">
                    <select
                      defaultValue={start_year}
                      onChange={e => setStartYear(e.target.value) }>
                      {
                        years.map(m => <option key={m + "from"}>{m}</option>)
                      }
                    </select>
                  </div>

                  <div className="input-field month-select">
                    <select
                      defaultValue={start_month}
                      onChange={e => setStartMonth(e.target.value) }>
                      {
                        months.map(m => <option key={m + "from"}>{m}</option>)
                      }
                    </select>
                  </div>

                  <div><span>to</span></div>

                  <div className="input-field year-select">
                    <select
                      defaultValue={end_year}
                      onChange={e => setEndYear(e.target.value)}>
                      {
                        years.map(m => <option key={m + "to"} >{m}</option>)
                      }
                    </select>
                  </div>

                  <div className="input-field month-select">
                    <select
                      defaultValue={end_month}
                      onChange={e => setEndMonth(e.target.value)}>
                      {
                        months.map(m => <option key={m + "to"}>{m}</option>)
                      }
                    </select>
                  </div>
                </div>
              </li>
            </ul>

            <h4 className="game-count center-align">
              {search(games_with_dates).length} games
            </h4>

            <div className="games center-align">
              <ul className="list">
                { search(games_with_dates).map( card_layout ) }
              </ul>
            </div>
          </div>
      );
}


export default App;
